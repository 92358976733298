<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <ValidationProvider name="Stock Type" vid="stock_type" rules="required|min_value:1" v-slot="{ errors }">
                <b-form-group label-for="stock_type">
                    <template v-slot:label>
                        {{ $t('teaGarden.stock_type') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-radio-group v-model="data.stock_type"
                        :options="stockTypeList"
                        value-field="value" text-field="text"
                        :state="errors[0] ? false : (valid ? true : null)">
                    </b-form-radio-group>
                    <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <ValidationProvider name="Return Type" vid="type" rules="required|min_value:1" v-slot="{ errors }">
                  <b-form-group label-for="type">
                      <template v-slot:label>
                          {{ $t('teaGarden.type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-radio-group v-model="data.type"
                          :options="garrdenFactoryTypeList"
                          value-field="value" text-field="text"
                          :state="errors[0] ? false : (valid ? true : null)">
                      </b-form-radio-group>
                      <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                      </div>
                  </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="data.type === 3">
            <ValidationProvider name="Bought Leaf Factory" vid="factory_search_id"  rules="required">
                <b-form-group
                class="row"
                label-for="factory_search_id "
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('teaGarden.bought_leaf_factory') }} <span class="text-danger">*</span>
                </template>
                <v-select name="factory_search_id"
                    v-model="search2.factory_search_id"
                    label="text"
                    option-value="value"
                    :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId() ? true : false"
                    :options= masterBoughtLeafFactoryList
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                />
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-else>
            <ValidationProvider name="Garden Name" vid="garden_search_id" rules="required">
                <b-form-group
                class="row"
                label-for="garden_search_id "
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  <span v-if="data.type === 1">{{$t('teaGardenConfig.select_garden')}}</span><span v-if="data.type === 2">{{$t('teaGardenConfig.select_garden')}}</span> <span class="text-danger">*</span>
                </template>
                <v-select name="garden_search_id"
                    v-model="search2.garden_search_id"
                    label="text"
                    option-value="value"
                    :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId() ? true : false"
                    :options= masterTeaGardenGenInfoList
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                />
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Date" vid="stock_date" rules="required">
              <b-form-group
                class="row"
                label-for="stock_date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('globalTrans.date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="stock_date"
                  v-model="data.stock_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :locale="currentLocale"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <center><h5 xs="12" sm="12" md="6" lg="6" xl="6"><b>{{ $t('teaGarden.available_stock') }}: {{ $n(search2.available_stock) }}</b></h5></center>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Stock In Quantity (Kg)" vid="stock_in_quantity" rules="required|min_value:1">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="stock_in_quantity">
                <template v-slot:label>
                 <span v-if="data.stock_type === 1">{{ $t('teaGarden.stock_in_quantity_x') }}</span> <span v-if="data.stock_type === 2">{{ $t('teaGarden.stock_out_quantity') }}</span> <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="stock_in_quantity"
                  type="number"
                  step="0.01"
                  min="0.00"
                  v-model="data.stock_quantity"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12" v-if="data.stock_quantity > 0">
                  <center><h5 xs="12" sm="12" md="6" lg="6" xl="6"><b>{{ $t('teaGarden.total_stock') }}:
                  <span v-if="data.stock_type === 1">{{ $n(parseFloat( parseFloat(search2.available_stock) +  parseFloat(data.stock_quantity))) }}</span>
                  <span v-if="data.stock_type === 2">{{ $n(parseFloat( parseFloat(search2.available_stock) -  parseFloat(data.stock_quantity))) }}</span>
                  <span v-if="data.stock_type === 2">
                    <span v-if="parseFloat( parseFloat(search2.available_stock) -  parseFloat(data.stock_quantity)) < 0" class="text-danger">
                      <br>
                      <span v-if="$i18n.locale === 'en'">Stock Is Empty! Stock Out is not Valid!</span>
                      <span v-if="$i18n.locale === 'bn'">স্টক খালি! স্টক আউট বৈধ নয়!</span>
                    </span>
                  </span>
                  </b></h5></center>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button :disabled="data.stock_type === 2 ? (parseFloat( parseFloat(search2.available_stock) -  parseFloat(data.stock_quantity)) < 0 ? true: false) : false" type="button" @click="saveUpdate(1)" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.save_draft') }}</b-button>
            <b-button :disabled="data.stock_type === 2 ? (parseFloat( parseFloat(search2.available_stock) -  parseFloat(data.stock_quantity)) < 0 ? true: false) : false" type="button" @click="saveUpdate(2)" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
            <b-button :disabled="data.stock_type === 2 ? (parseFloat( parseFloat(search2.available_stock) -  parseFloat(data.stock_quantity)) < 0 ? true: false) : false" variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { gardenStockInOutStore, gardenStockInOutUpdate, gardenCurrentStockList } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'

export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
  data () {
    return {
      ItemShow: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      CityCorpItemShow: false,
      PauroshobaItemShow: false,
      UnionItemShow: false,
      processShow: true,
      processShowR: false,
      roundShow: true,
      roundShowR: false,
      valid: null,
      conditionStatus: '',
      fileRequired: 'required',
      isGardenAdmin: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      office_id: 0,
      search2: {
        available_stock: 0,
        garden_search_id: '',
        factory_search_id: ''
      },
      stockType: [
        {
          value: 1,
          text: 'Stock In',
          text_en: 'Stock In',
          text_bn: 'স্টক ইন'
        },
        {
          value: 2,
          text: 'Stock Out',
          text_en: 'Stock Out',
          text_bn: 'স্টক আউট'
        }
      ],
      data: {
        garden_id: 0,
        stock_type: 1,
        type: 1,
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        stock_date: '',
        status: 1,
        inserted_by: 2,
        stock_quantity: ''
      },
      gardenList: [],
      maxAppQty: 0
    }
  },
  created () {
    if (this.isGardenAdminCheckGardenId() > 0) {
      this.search2.garden_search_id = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(el => el.value === this.isGardenAdminCheckGardenId())
      this.data.type = 1
    }
    if (this.isFactoryAdminCheckFactoryId() > 0) {
      this.search2.factory_search_id = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(el => el.value === this.isFactoryAdminCheckFactoryId())
      this.data.type = 3
    }
    if (this.id) {
      const tmp = this.gdnStockList()
      this.data = tmp
      if (this.data.stock_type === 2) {
        this.data.stock_quantity = parseFloat(tmp.stock_quantity * -1)
      }
      this.search2.garden_search_id = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.value === this.data.garden_id)
      this.search2.factory_search_id = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.value === this.data.factory_id)
            this.getStockQuantity()
    }
  },
  computed: {
    masterTeaGardenGenInfoList () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
    },
    masterBoughtLeafFactoryList () {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1)
    },
    stockTypeList () {
        return this.stockType.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    garrdenFactoryTypeList () {
        let filteredList = this.$store.state.TeaGardenService.commonObj.garrdenFactoryTypeList
        if (this.isGardenAdminCheckGardenId() > 0) {
           filteredList = filteredList.filter(item => item.value !== 3)
        }
        if (this.isFactoryAdminCheckFactoryId() > 0) {
           filteredList = filteredList.filter(item => item.value > 2)
        }
        return filteredList.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    unitList () {
      return this.$store.state.TeaGardenService.commonObj.unitList
    },
    divisionList () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    },
    getAreaTypeList: function () {
      const objectData = this.$store.state.commonObj.areaTypeList
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    applicationPhaseList: function () {
      return this.$store.state.TeaGardenService.commonObj.applicationPhaseList
    },
    currentLocale () {
        return this.$i18n.locale
    }
  },
  watch: {
    'data.stock_date': function () {
      const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      const currentDate = new Date(this.data.stock_date)
      const month = currentDate.getMonth() + 1
      const year = currentDate.getFullYear()
      let year1st = year
      if (month > 6 && month < 13) {
          year1st = year
      }
      if (month < 7 && month >= 1) {
          year1st = year - 1
      }
      const yearF = year1st + '-' + (year1st + 1)
      let currentFiscalYearId = 0
      fiscalYearList.forEach(element => {
        if (element.text_en === yearF) {
          currentFiscalYearId = element.value
        }
      })
      this.data.fiscal_year_id = currentFiscalYearId
    },
    'data.stock_type': function () {
      if (this.search2.garden_search_id.value > 0) {
        this.getStockQuantity()
      }
    },
    'data.type': function () {
      if (this.search2.garden_search_id.value > 0) {
        this.getStockQuantity()
      }
    },
    'search2.garden_search_id': function () {
      if (this.search2.garden_search_id.value > 0) {
        this.data.garden_id = this.search2.garden_search_id.value
        this.data.factory_id = this.search2.factory_search_id.value
        this.getStockQuantity()
      }
    },
    'search2.factory_search_id': function () {
      if (this.search2.factory_search_id.value > 0) {
        this.data.garden_id = this.search2.garden_search_id.value
        this.data.factory_id = this.search2.factory_search_id.value
        this.getStockQuantity()
      }
    }
  },
  methods: {
    async getStockQuantity () {
            this.search2.available_stock = 0
            const result = await RestApi.postData(teaGardenServiceBaseUrl, gardenCurrentStockList, this.data)
            if (result.success) {
              this.search2.available_stock = result.data.stock_quantity
            } else {
              this.search2.available_stock = 0
            }
    },
    async saveUpdate (status = 0) {
        if (this.data.type < 3) {
          if (this.data.garden_id > 0) {
              this.data.factory_id = ''
          }
        }
        if (this.data.type === 3) {
          if (this.data.factory_id > 0) {
              this.data.garden_id = ''
          }
        }
      var check = await this.$refs.form.validate()
      if (check) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        this.data.status = status
        if (this.id) {
            result = await RestApi.putData(teaGardenServiceBaseUrl, `${gardenStockInOutUpdate}/${this.id}`, this.data)
        } else {
            result = await RestApi.postData(teaGardenServiceBaseUrl, gardenStockInOutStore, this.data)
        }
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-form')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    gdnStockList () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
